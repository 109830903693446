import {Color} from "./Color.js";

const BASE_COLORS = {
	default: {
		bg: new Color(68, 68, 68),
		text: new Color(204, 204, 204),
		glow: new Color(254, 255, 196),
		saturated: new Color(251, 255, 0),
	},
	face: {
		bg: new Color(72, 72, 72),
		text: new Color(204, 204, 204),
		glow: new Color(254, 255, 196),
		saturated: new Color(251, 255, 0),
	},
	light: {
		bg: new Color(170, 170, 170),
		text: new Color(80, 80, 80),
		glow: new Color(254, 255, 196),
		saturated: new Color(251, 255, 0),
	},
	danger: {
		bg: new Color(173, 69, 90),
		text: new Color(241, 241, 241),
		glow: new Color(255, 255, 255),
		saturated: new Color(245, 29, 72),
	},
	warning: {
		bg: new Color(214, 177, 73),
		text: new Color(61, 60, 56),
		glow: new Color(159, 249, 255),
		saturated: new Color(255, 188, 0),
	},
	success: {
		bg: new Color(58, 126, 105),
		text: new Color(228, 242, 238),
		glow: new Color(116, 255, 202),
		saturated: new Color(0, 255, 176),
	},
	info: {
		bg: new Color(32, 132, 168),
		text: new Color(237, 251, 255),
		glow: new Color(161, 235, 255),
		saturated: new Color(0, 188, 255),
	},
};

/**
 * @typedef {object} ColorsSet
 * @property {Color} bg
 * @property {Color} text
 * @property {Color} glow
 * @property {Color} saturated
 * @property {Color} textDark1
 * @property {Color} textDark2
 * @property {Color} textShadow
 * @property {Color} bgLight1
 * @property {Color} bgLight2
 * @property {Color} bgDark1
 * @property {Color} bgDark2
 */

/**
 * @type {Object<ColorsSet>}
 */
const COLORS = {
	default: {
		...BASE_COLORS.default,
		textDark1: BASE_COLORS.default.text.changeLightness(-0.15),
		textDark2: BASE_COLORS.default.text.changeLightness(-0.25),
		textShadow: BASE_COLORS.default.bg.changeLightness(-0.1),
		bgLight1: BASE_COLORS.default.bg.changeLightness(0.1),
		bgLight2: BASE_COLORS.default.bg.changeLightness(0.15),
		bgDark1: BASE_COLORS.default.bg.changeLightness(-0.05),
		bgDark2: BASE_COLORS.default.bg.changeLightness(-0.08),
	},
	face: {
		...BASE_COLORS.face,
		textDark1: BASE_COLORS.face.text.changeLightness(-0.15),
		textDark2: BASE_COLORS.face.text.changeLightness(-0.25),
		textShadow: BASE_COLORS.default.bg.changeLightness(-0.1), // use default bg color
		bgLight1: BASE_COLORS.default.bg.changeLightness(0.1), // use default bg color
		bgLight2: BASE_COLORS.default.bg.changeLightness(0.15), // use default bg color
		bgDark1: BASE_COLORS.default.bg.changeLightness(-0.05), // use default bg color
		bgDark2: BASE_COLORS.default.bg.changeLightness(-0.08), // use default bg color
	},
	light: {
		...BASE_COLORS.light,
		textDark1: BASE_COLORS.light.text.changeLightness(-0.15),
		textDark2: BASE_COLORS.light.text.changeLightness(-0.25),
		textShadow: BASE_COLORS.light.bg.changeLightness(0.1),
		bgLight1: BASE_COLORS.light.bg.changeLightness(0.15),
		bgLight2: BASE_COLORS.light.bg.changeLightness(0.25),
		bgDark1: BASE_COLORS.light.bg.changeLightness(-0.2),
		bgDark2: BASE_COLORS.light.bg.changeLightness(-0.3),
	},
	danger: {
		...BASE_COLORS.danger,
		textDark1: BASE_COLORS.danger.text.changeLightness(-0.15),
		textDark2: BASE_COLORS.danger.text.changeLightness(-0.25),
		textShadow: BASE_COLORS.danger.bg.changeLightness(-0.15),
		bgLight1: BASE_COLORS.danger.bg.changeLightness(0.15),
		bgLight2: BASE_COLORS.danger.bg.changeLightness(0.25),
		bgDark1: BASE_COLORS.danger.bg.changeLightness(-0.15),
		bgDark2: BASE_COLORS.danger.bg.changeLightness(-0.25),
	},
	warning: {
		...BASE_COLORS.warning,
		textDark1: BASE_COLORS.warning.text.changeLightness(-0.05),
		textDark2: BASE_COLORS.warning.text.changeLightness(-0.25),
		textShadow: BASE_COLORS.warning.bg.changeLightness(0.1),
		bgLight1: BASE_COLORS.warning.bg.changeLightness(0.15),
		bgLight2: BASE_COLORS.warning.bg.changeLightness(0.25),
		bgDark1: BASE_COLORS.warning.bg.changeLightness(-0.2),
		bgDark2: BASE_COLORS.warning.bg.changeLightness(-0.3),
	},
	success: {
		...BASE_COLORS.success,
		textDark1: BASE_COLORS.success.text.changeLightness(-0.15),
		textDark2: BASE_COLORS.success.text.changeLightness(-0.25),
		textShadow: BASE_COLORS.success.bg.changeLightness(-0.15),
		bgLight1: BASE_COLORS.success.bg.changeLightness(0.15),
		bgLight2: BASE_COLORS.success.bg.changeLightness(0.25),
		bgDark1: BASE_COLORS.success.bg.changeLightness(-0.2),
		bgDark2: BASE_COLORS.success.bg.changeLightness(-0.3),
	},
	info: {
		...BASE_COLORS.info,
		textDark1: BASE_COLORS.info.text.changeLightness(-0.15),
		textDark2: BASE_COLORS.info.text.changeLightness(-0.25),
		textShadow: BASE_COLORS.info.bg.changeLightness(-0.15),
		bgLight1: BASE_COLORS.info.bg.changeLightness(0.15),
		bgLight2: BASE_COLORS.info.bg.changeLightness(0.25),
		bgDark1: BASE_COLORS.info.bg.changeLightness(-0.2),
		bgDark2: BASE_COLORS.info.bg.changeLightness(-0.3),
	},
};

export const FONT_FAMILY = 'Tahoma, Helvetica, Arial, Verdana';

const FONT_SIZE = {
	default: 13,
	medium: 12,
	small: 11,
	kbd: 10,
};

const INDENT = [5, 7, 10, 15, 20];

const BASE_HEIGHT = [20, 28, 36];
const BORDER_RADIUS = [3, 6];

export class Style {
	static #colorsCache = new Map();

	/**
	 * @param {string} nameOrColor
	 * @returns {ColorsSet}
	 */
	static getColorsSet(nameOrColor = 'default') {
		if (COLORS[nameOrColor]) {
			return COLORS[nameOrColor];
		}

		let colorObj = Style.#colorsCache.get(nameOrColor);
		if (colorObj) {
			return colorObj;
		}

		let color;
		try {
			if (nameOrColor.startsWith('rgb(')) {
				color = Color.createFromRgb(nameOrColor);
			} else {
				color = Color.createFromHex(nameOrColor);
			}
		} catch (e) {
			return COLORS.default;
		}

		colorObj = {
			bg: color,
			text: color,
			glow: color,
			saturated: color,
			textDark1: color.changeLightness(-0.15),
			textDark2: color.changeLightness(-0.25),
			textShadow: color.changeLightness(-0.1),
			bgLight1: color.changeLightness(0.1),
			bgLight2:color.changeLightness(0.15),
			bgDark1: color.changeLightness(-0.05),
			bgDark2: color.changeLightness(-0.08),
		}

		Style.#colorsCache.set(nameOrColor, colorObj);

		return colorObj;
	}

	/**
	 * @param {string} size
	 * @returns {string}
	 */
	static getFontSizePx(size = 'default') {
		return `${FONT_SIZE[size] || FONT_SIZE.default}px`;
	}

	/**
	 * @param {string|number} size
	 * @param {number} extraIndent
	 * @returns {string}
	 */
	static getIndentPx(size, extraIndent = 0) {
		if (size === '' || size <= 0) {
			return '0px';
		}

		return `${Math.max(0, (INDENT[parseInt(size) - 1] || INDENT[0]) + extraIndent)}px`;
	}

	/**
	 * @param {string|number} size
	 * @param {number} extraIndent
	 * @returns {number}
	 */
	static getIndentDec(size, extraIndent = 0) {
		if (size === '' || size <= 0) {
			return 0;
		}

		return Math.max(0, (INDENT[parseInt(size) - 1] || INDENT[0]) + extraIndent);
	}

	/**
	 * @param {string|number} size
	 * @returns {string}
	 */
	static getBaseHeightPx(size) {
		return `${Style.getBaseHeightDec(size)}px`;
	}

	/**
	 * @param {string|number} size
	 * @returns {number}
	 */
	static getBaseHeightDec(size) {
		if (size === '' || size <= 0) {
			return 0;
		}

		return BASE_HEIGHT[parseInt(size) - 1] || BASE_HEIGHT[0];
	}

	/**
	 * @param {string|number} size
	 * @param {number} extraRadius
	 * @returns {string}
	 */
	static getBorderRadiusPx(size, extraRadius = 0) {
		if (size === '' || size <= 0) {
			return '0px';
		}

		return `${Math.max(0, (BORDER_RADIUS[parseInt(size) - 1] || BORDER_RADIUS[0]) + extraRadius)}px`;
	}

	/**
	 * @param {string|number} size
	 * @returns {number}
	 */
	static getBorderRadiusDec(size) {
		if (size === '' || size <= 0) {
			return 0;
		}

		return BORDER_RADIUS[parseInt(size) - 1] || BORDER_RADIUS[0];
	}
}
