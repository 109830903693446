var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"file\">\n							<span class=\"filename\">"
    + alias1(__default(require("../../../handlebars-helpers/i18n.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"buttons.frame-no",(depth0 != null ? lookupProperty(depth0,"index") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":155,"column":30},"end":{"line":155,"column":68}}}))
    + "</span>\n							<span class=\"size\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"size") : depth0), depth0))
    + "</span>\n							<f-button icon=\"download\" small onclick=\"window.open('"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "')\"></f-button>\n						</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<f-box init-state=\"about-dialog\" flex align-items=\"stretch\" margin=\"2\" gap=\"2\">\n	<f-screen min-screen-width=\"840\" loading:if=\"state(this).videoLoaded !== true\" width=\"444\" height=\"430\">\n		<template>\n			<video width=\"444\" height=\"388\" autoplay muted loop playsinline pip=\"false\"\n				onloadeddata=\"state(this).videoLoaded = true\"\n				oncanplaythrough=\"state(this).videoLoaded = true\"\n				>\n				<source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":17},"end":{"line":8,"column":30}}}) : helper)))
    + "/images/station-v4.mov\" type=\"video/quicktime\">\n				<source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":17},"end":{"line":9,"column":30}}}) : helper)))
    + "/images/station-v3.webm\" type=\"video/webm; codecs=&quot;vp9&quot;\">\n				<source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":10,"column":17},"end":{"line":10,"column":30}}}) : helper)))
    + "/images/station-v3.mp4\" type=\"video/mp4\">\n			</video>\n		</template>\n	</f-screen>\n	<f-box init=\"state(this).tab = 1\" flex flex-max column align-items=\"start\" height=\"430\">\n		<f-box flex>\n			<f-button onclick=\"state(this).tab = 1\" pressed:if=\"state(this).tab === 1\" caption=\""
    + alias4(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.project",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":87},"end":{"line":16,"column":111}}}))
    + "\" group-position=\"left\" small tool-mode></f-button>\n			<f-button onclick=\"state(this).tab = 2\" pressed:if=\"state(this).tab === 2\" caption=\"FAQ\" group-position=\"center\" small tool-mode></f-button>\n			<f-button onclick=\"state(this).tab = 3\" pressed:if=\"state(this).tab === 3\" caption=\""
    + alias4(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.download",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":87},"end":{"line":18,"column":112}}}))
    + "\" group-position=\"center\" small tool-mode></f-button>\n			<f-button onclick=\"state(this).tab = 4\" pressed:if=\"state(this).tab === 4\" caption=\""
    + alias4(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.contacts",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":87},"end":{"line":19,"column":112}}}))
    + "\" group-position=\"right\" small tool-mode></f-button>\n		</f-box>\n		<f-box flex-max color=\"face\" y-scroll=\"auto\" width=\"100%\">\n			<f-box show:if=\"state(this).tab === 1\" padding=\"3\">\n				<div style=\"text-align: justify;\">\n					<f-bulb color=\"success\" on></f-bulb>\n					"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.project",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":5},"end":{"line":25,"column":31}}})) != null ? stack1 : "")
    + "\n				</div>\n				<f-box color=\"info\" margin-top=\"2\" style=\"text-align: justify;\">\n					"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.interactive",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":5},"end":{"line":28,"column":35}}})) != null ? stack1 : "")
    + "\n				</f-box>\n				<f-box margin-top=\"3\" style=\"text-align: center;\">\n					<div style=\"margin-bottom: 5px\">\n						"
    + alias4(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"messages.project-completion",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":32,"column":44}}}))
    + " <f-value value:from=\"state().projectProgressPercent\"></f-value>%\n					</div>\n					<f-progress width=\"200\" value:from=\"state().projectProgressPercent\"></f-progress>\n				</f-box>\n			</f-box>\n\n			<f-box show:if=\"state(this).tab === 2\">\n				<template>\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.how-many-people.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":25},"end":{"line":40,"column":71}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.how-many-people.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":6},"end":{"line":41,"column":52}}})) != null ? stack1 : "")
    + "\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.software.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":44,"column":25},"end":{"line":44,"column":64}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.software.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":45,"column":6},"end":{"line":45,"column":45}}})) != null ? stack1 : "")
    + "\n						<f-button color=\"info\" icon=\"link\" small onclick=\"window.open('/editor/l0')\"></f-button>\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.how-long.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":25},"end":{"line":49,"column":64}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.how-long.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":50,"column":6},"end":{"line":50,"column":45}}})) != null ? stack1 : "")
    + "\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.why-796.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":53,"column":25},"end":{"line":53,"column":63}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.why-796.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":54,"column":6},"end":{"line":54,"column":44}}})) != null ? stack1 : "")
    + "\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.video.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":57,"column":25},"end":{"line":57,"column":61}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						<template>\n							"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.video.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":7},"end":{"line":59,"column":43}}})) != null ? stack1 : "")
    + "\n							<div style=\"margin-top: 5px\">\n								<f-button color=\"danger\" icon=\"link\" caption=\"YouTube\" small onclick=\"window.open('https://www.youtube.com/channel/UCribkEGzOuMQ9ozb0ektMCQ')\"></f-button>\n								<f-button color=\"info\" icon=\"link\" caption=\"ArtStation\" small onclick=\"window.open('https://www.artstation.com/floor796')\"></f-button>\n							</div>\n						</template>\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":25},"end":{"line":67,"column":68}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						<template>\n							<f-box flex column gap=\"2\">\n								<div>\n									<f-bulb color=\"info\" on></f-bulb> "
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.details.waldo",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":71,"column":43},"end":{"line":71,"column":92}}})) != null ? stack1 : "")
    + "\n								</div>\n								<div>\n									<f-bulb color=\"info\" on></f-bulb> "
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.details.quest1",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":74,"column":43},"end":{"line":74,"column":93}}})) != null ? stack1 : "")
    + "\n								</div>\n								<div>\n									<f-bulb color=\"info\" on></f-bulb> "
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.details.statistics",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":77,"column":43},"end":{"line":77,"column":97}}})) != null ? stack1 : "")
    + "\n								</div>\n								<div>\n									<f-bulb color=\"info\" on></f-bulb> "
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.details.racer796",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":80,"column":43},"end":{"line":80,"column":95}}})) != null ? stack1 : "")
    + "\n								</div>\n								<div>\n									<f-bulb color=\"info\" on></f-bulb> "
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.details.change-my-mind",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":83,"column":43},"end":{"line":83,"column":101}}})) != null ? stack1 : "")
    + "\n								</div>\n								<div>\n									<f-bulb color=\"info\" on></f-bulb> "
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.details.melody",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":86,"column":43},"end":{"line":86,"column":93}}})) != null ? stack1 : "")
    + "\n								</div>\n								<div>\n									<f-bulb color=\"info\" on></f-bulb> "
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.interactives.details.fun-drawing",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":89,"column":43},"end":{"line":89,"column":98}}})) != null ? stack1 : "")
    + "\n								</div>\n							</f-box>\n						</template>\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.first-character.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":95,"column":25},"end":{"line":95,"column":71}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						<template>\n							"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.first-character.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":97,"column":7},"end":{"line":97,"column":53}}})) != null ? stack1 : "")
    + "\n							<f-screen width=\"240\" height=\"250\" style=\"margin: 5px auto\" loading:if=\"state(this).firstCharVideoLoaded !== true\">\n								<video width=\"230\" height=\"240\" autoplay muted loop playsinline pip=\"false\"\n									onloadeddata=\"state(this).firstCharVideoLoaded = true\"\n									oncanplaythrough=\"state(this).firstCharVideoLoaded = true\">\n									<source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":102,"column":22},"end":{"line":102,"column":35}}}) : helper)))
    + "/images/first-char-v2.mp4\" type=\"video/mp4\" />\n								</video>\n							</f-screen>\n							"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.first-character.details2",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":105,"column":7},"end":{"line":105,"column":54}}})) != null ? stack1 : "")
    + "\n						</template>\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.weight.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":109,"column":25},"end":{"line":109,"column":62}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.weight.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":110,"column":6},"end":{"line":110,"column":43}}})) != null ? stack1 : "")
    + "\n						<f-value value:from=\"state().totalWeightF796\"></f-value>\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.render.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":114,"column":25},"end":{"line":114,"column":62}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						<template>\n							"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.render.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":116,"column":7},"end":{"line":116,"column":44}}})) != null ? stack1 : "")
    + "\n							<f-button color=\"info\" icon=\"link\" caption=\"Habr\" small onclick=\"window.open('https://habr.com/ru/companies/floor796/articles/673318/')\"></f-button>\n						</template>\n					</f-details>\n\n					<f-details summary=\""
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.inspiration.summary",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":121,"column":25},"end":{"line":121,"column":67}}})) != null ? stack1 : "")
    + "\" group=\"about\">\n						"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.faq.inspiration.details",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":122,"column":6},"end":{"line":122,"column":48}}})) != null ? stack1 : "")
    + "\n					</f-details>\n				</template>\n			</f-box>\n\n\n			<f-box show:if=\"state(this).tab === 3\" padding=\"3\">\n				<template>\n					<div>\n						<f-bulb color=\"success\" on></f-bulb>\n						"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.download",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":132,"column":6},"end":{"line":132,"column":33}}})) != null ? stack1 : "")
    + "\n					</div>\n					<style>\n						.file {\n							display: flex;\n							align-items: center;\n							margin-top: 5px;\n\n							& .filename {\n								width: 160px;\n							}\n\n							& .size {\n								width: 60px;\n							}\n\n							& span {\n								margin-left: 5px;\n							}\n						}\n					</style>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":5},"end":{"line":159,"column":14}}})) != null ? stack1 : "")
    + "				</template>\n			</f-box>\n\n			<f-box show:if=\"state(this).tab === 4\" padding=\"3\">\n				<template>\n					"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.contacts.email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":165,"column":5},"end":{"line":165,"column":38}}})) != null ? stack1 : "")
    + "\n					<f-box flex gap=\"1\" margin-top=\"2\" margin-bottom=\"4\">\n						<f-input-text value=\"info@floor796.com\" readonly></f-input-text>\n						<f-button small icon=\"copy\" onclick=\"navigator.clipboard.writeText('info@floor796.com');\"></f-button>\n					</f-box>\n					"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.contacts.alt",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":170,"column":5},"end":{"line":170,"column":36}}})) != null ? stack1 : "")
    + "\n					<f-box flex gap=\"1\" margin-top=\"2\" margin-bottom=\"4\">\n						<f-button icon=\"reddit\" icon-size=\"4\" color=\"danger\" caption=\"Reddit\" onclick=\"window.open('https://www.reddit.com/user/floor796/submitted/')\"></f-button>\n						<f-button icon=\"pikabu\" icon-size=\"4\" color=\"success\" caption=\"Pikabu\" onclick=\"window.open('https://pikabu.ru/@0x00')\"></f-button>\n						<f-button icon=\"artstation\" icon-size=\"4\" color=\"info\" caption=\"ArtStation\" onclick=\"window.open('https://www.artstation.com/floor796')\"></f-button>\n					</f-box>\n					"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"about.contacts.follow",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":176,"column":5},"end":{"line":176,"column":39}}})) != null ? stack1 : "")
    + "\n					<f-box flex gap=\"1\" margin-top=\"2\">\n						<f-button icon=\"telegram\" icon-size=\"4\" color=\"info\" caption=\"Telegram\" onclick=\"window.open('https://t.me/floor796')\"></f-button>\n						<f-button icon=\"x\" icon-size=\"4\" color=\"light\" caption=\"X (Twitter)\" onclick=\"window.open('https://x.com/Floor_796')\"></f-button>\n					</f-box>\n				</template>\n			</f-box>\n		</f-box>\n	</f-box>\n</f-box>\n";
},"useData":true});