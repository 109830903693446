var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"header\">\n	<f-box init-state=\"header\" color=\"default\" no-light-borders gap:by-window-width=\"{360: 0, 700:1, max:3}\" padding-right=\"1\" padding-left=\"1\" square flex class=\"header__line\">\n		<f-box flex gap=\"1\" state-name=\"logoBtn\" text-shadow font-size=\"medium\" style=\"margin-bottom: 2px\">\n			<f-icon icon=\"logo\" size=\"6\" min-screen-width=\"360\"></f-icon>\n			<f-slot min-screen-width=\"700\" translate=\"no\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"projectName") || (depth0 != null ? lookupProperty(depth0,"projectName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"projectName","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":71}}}) : helper)))
    + "\"></f-slot>\n		</f-box>\n		<f-box flex font-size=\"small\">\n			<f-button state-name=\"aboutBtn\" group-position=\"left\" tool-mode small caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.about",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":82},"end":{"line":8,"column":106}}}))
    + "\"></f-button>\n			<f-button state-name=\"subsBtn\" group-position=\"center\" small tool-mode caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.subscribe",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":83},"end":{"line":9,"column":111}}}))
    + "\"></f-button>\n			<f-button state-name=\"changesBtn\" bulb-color=\"warning\" group-position=\"right\" tool-mode small caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.changes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":106},"end":{"line":10,"column":132}}}))
    + "\"></f-button>\n			<f-box min-screen-width=\"800\" flex gap=\"1\" margin-left=\"2\" show:if=\"state().lastUpdateDays !== undefined\" font-size=\"medium\" text-muted text-shadow style=\"margin-bottom: 2px\">\n				<f-slot value:from=\"state().lastUpdateDaysStartStr\">Последнее обновление</f-slot>\n				<f-value small value:from=\"state().lastUpdateDays\"></f-value>\n				<f-slot value:from=\"state().lastUpdateDaysEndStr\">day ago</f-slot>\n			</f-box>\n		</f-box>\n		<f-box flex-max></f-box>\n		<f-box flex style=\"margin-bottom: 2px\" min-screen-width=\"700\">\n			<f-checkbox state-name=\"wanderingBtn\" font-size=\"medium\" caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.wander",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":69},"end":{"line":19,"column":94}}}))
    + "\"></f-checkbox>\n		</f-box>\n		<f-box flex>\n			<f-button state-name=\"langEnBtn\" group-position=\"left\" tool-mode small caption=\"en\"></f-button>\n			<f-button state-name=\"langRuBtn\" group-position=\"right\" tool-mode small caption=\"ru\"></f-button>\n		</f-box>\n	</f-box>\n</header>\n";
},"useData":true});