import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

class LineElement extends AbstractCustomElement {
	attrs = {
		height: 0,
		margin: -1,
		marginRight: -1,
		marginLeft: -1,
		marginTop: -1,
		marginBottom: -1,
	};

	async render() {
		const colors = Style.getColorsSet();
		this.shadowRoot.innerHTML = /* html */`
			<style>
				:host {
					display: block;
					height: ${this.attrs.height}px;
					background-color: ${colors.bgDark1};
					border-bottom: 1px solid ${colors.bgLight1};
					border-top: 1px solid ${colors.bgDark2};

					${this.attrs.margin > -1 ? `margin: ${Style.getIndentPx(this.attrs.margin)};` : ''}
					${this.attrs.marginRight > -1 ? `margin-right: ${Style.getIndentPx(this.attrs.marginRight)};` : ''}
					${this.attrs.marginLeft > -1 ? `margin-left: ${Style.getIndentPx(this.attrs.marginLeft)};` : ''}
					${this.attrs.marginBottom > -1 ? `margin-bottom: ${Style.getIndentPx(this.attrs.marginBottom)};` : ''}
					${this.attrs.marginTop > -1 ? `margin-top: ${Style.getIndentPx(this.attrs.marginTop)};` : ''}
				}
			</style>
		`;
	}
}

customElements.define('f-line', LineElement);
