const states = new Map();
const cache = new WeakMap();
const deleteTimers = new Map();

/** @type {Map<string,function[]>} */
let stateCreationObservers = new Map();

function cancelDeletionTimer(name) {
	if (deleteTimers.has(name)) {
		clearTimeout(deleteTimers.get(name));
		deleteTimers.delete(name);
	}
}

export function getState(name) {
	cancelDeletionTimer(name);
	return states.get(name);
}

export function deleteState(name, delay = 100) {
	cancelDeletionTimer(name);

	if (delay === 0) {
		states.delete(name);
	} else {
		deleteTimers.set(name, setTimeout(() => deleteState(name, 0), delay));
	}
}

export function createState(name) {
	cancelDeletionTimer(name);

	if (states.has(name)) {
		console.error(`[FT] The State with name "${name}" already exists`);
		return states.get(name);
	}

	const propObservers = new Map();

	const s = new Proxy({
		waitProp(name) {
			if (name in this) {
				return Promise.resolve(this[name]);
			}

			return new Promise(r => {
				const list = propObservers.get(name) || [];
				list.push(r);
				propObservers.set(name, r);
			});
		},

		getName() {
			return name;
		}
	}, {
		get(target, prop) {
			return target[prop];
		},

		set(target, prop, value) {
			if (target[prop] === value) {
				return true;
			}

			const isNew = !(name in target);

			target[prop] = value;
			document.dispatchEvent(new CustomEvent(`state(${name}).${prop}`, {detail: value}));

			if (isNew && propObservers.has(name)) {
				propObservers.get(name).map(h => h());
				propObservers.delete(name);
			}

			return true;
		},
	});

	states.set(name, s);

	const list = stateCreationObservers.get(name);
	if (list) {
		list.map(h => h(s));
		stateCreationObservers.delete(name);
	}

	return s;
}

export function getStateForElement(el) {
	let state;

	if (cache.has(el)) {
		state = getState(cache.get(el));
		if (state) {
			return state;
		}

		cache.delete(el);
	}

	let host = el;
	let safeIter = 1000;
	while (host && --safeIter > 0) {
		if (host.hasAttribute('init-state')) {
			state = getState(host.getAttribute('init-state'));
			if (!state) {
				state = createState(host.getAttribute('init-state'));
			}

			cache.set(el, state.getName());
			return state;
		}

		if (host.parentElement !== null && host.parentElement !== host) {
			host = host.parentElement;
		} else {
			host = host.getRootNode()?.host;
		}
	}

	if (safeIter <= 0) {
		console.error('Dead loop found while searching parent state object');
	}

	state = states.get('');
	cache.set(el, '');

	return state;
}

globalThis.rootState = createState('');

globalThis.waitState = (name) => {
	if (states.has(name)) {
		return Promise.resolve(states.get(name));
	}

	return new Promise(r => {
		const list = stateCreationObservers.get(name) || [];
		list.push(r);
		stateCreationObservers.set(name, list)
	});
};

/**
 *
 * @param {null|HTMLElement|string} target
 * @returns {{getName(): string, waitProp(): Promise<*>}}
 */
globalThis.state = (target = null) => {
	if (target === null) {
		return getState('');
	}

	if (typeof target === 'string') {
		return getState(target);
	}

	return getStateForElement(target);
};
