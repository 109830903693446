export class Color {
	/** @type {number[]} */
	#rgba;

	/** @type {string} */
	#cssValue = '';

	constructor(r = 255, g = 255, b = 255, a = 255) {
		this.#rgba = [r, g, b, a];
	}

	static createFromHex(value) {
		const match = `${value}`.match(/^#?([0-9A-F]{3}|[0-9A-F]{6})$/i);
		if (match === null) {
			throw new Error('Invalid hex color: ' + value);
		}

		value = match[1];
		if (value.length === 3) {
			value = parseInt(value + value, 16);
		} else {
			value = parseInt(value, 16);
		}

		return new Color(
			(value >>> 16) & 0xFF,
			(value >>> 8) & 0xFF,
			value & 0xFF
		);
	}

	static createFromRgb(value) {
		const match = `${value}`.match(/^rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)$/i);
		if (match === null) {
			throw new Error('Invalid rgb color: ' + value);
		}

		return new Color(
			parseInt(match[1], 10) & 0xFF,
			parseInt(match[2], 10) & 0xFF,
			parseInt(match[3], 10) & 0xFF
		);
	}

	get rgb() {
		return this.#rgba.slice(0, 3);
	}

	/**
	 * @type {string}
	 */
	get cssValue() {
		if (this.#cssValue) {
			return this.#cssValue;
		}

		if (this.#rgba[3] === 255) {
			this.#cssValue = `rgb(${this.#rgba[0]}, ${this.#rgba[1]}, ${this.#rgba[2]})`;
		} else {
			this.#cssValue = `rgba(${this.#rgba[0]}, ${this.#rgba[1]}, ${this.#rgba[2]}, ${this.#rgba[3] / 255})`;
		}

		return this.#cssValue;
	}

	toString() {
		return this.cssValue;
	}

	/**
	 * @param {number} deltaFactor
	 * @returns {Color}
	 */
	changeLightness(deltaFactor) {
		const c = new Color();
		c.#rgba = this.#rgba.slice();

		const shift = (255 * deltaFactor) | 0;
		for (let i = 0; i < 3; i++) {
			c.#rgba[i] = Math.max(0, Math.min(255, c.#rgba[i] + shift));
		}

		return c;
	}

	#resetCache() {
		this.#cssValue = '';
	}
}
