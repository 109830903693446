var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<f-box color=\"default\" init-state=\"changes-bar\" flex align-items=\"start\" column square class=\"changes-bar\">\n	<f-box flex padding-left=\"1\" padding-top=\"1\">\n		<f-button state-name=\"generalBtn\" pressed:if=\"state(this).mode === 'general'\" small group-position=\"left\" pressed=\"true\" caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.general-updates",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":132},"end":{"line":3,"column":166}}}))
    + "\" tool-mode></f-button>\n		<f-button state-name=\"interactivesBtn\" pressed:if=\"state(this).mode === 'interactives'\" bulb-on:if=\"state(this).unreadInteractives\" small group-position=\"right\" bulb-color=\"warning\" caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.interactives",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":193},"end":{"line":4,"column":224}}}))
    + "\" tool-mode></f-button>\n	</f-box>\n	<f-box width=\"100%\" margin=\"1\" show:if=\"state(this).mode === 'general'\" flex>\n		<f-input-text width=\"100%\" placeholder=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.search",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":65}}}))
    + "\" state-name=\"searchEl\"></f-input-text>\n	</f-box>\n	<f-box show:if=\"!!state(this).error\" color=\"danger\">\n		<f-slot value:from=\"state(this).error\"></f-slot>\n	</f-box>\n	<f-box state-name=\"listEl\" width=\"100%\" y-scroll=\"auto\" color=\"face\" flex-max padding=\"1\" padding-left=\"0\" margin-bottom=\"1\" margin-left=\"1\" margin-right=\"1\">\n		<div data-role=\"list\">\n			<div data-role=\"bottom-anchor\"></div>\n		</div>\n	</f-box>\n</f-box>\n";
},"useData":true});