import {AbstractCustomElement} from "../AbstractCustomElement.js";
import iconsSource from "./icons.svg"
import {Style} from "ui/components/styles/Style";

const MIN_SIZE_PX = 10;
const SIZE_STEP_PX = 2;

class SvgIconElement extends AbstractCustomElement {
	attrs = {
		icon: 'warning',
		color: '',
		shadow: true,
		useBgColor: false,
		size: 2,
	};

	/**
	 * @type {Map<string,{viewBox: string, content: string}>|null}
	 */
	static #icons = null;

	async render() {
		if (this.attrs.icon === '') {
			this.shadowRoot.innerHTML = '';
			return;
		}

		if (!SvgIconElement.#icons) {
			this.#buildIconsElemFromString(decodeURIComponent(iconsSource));
		}

		const iconName = this.attrs.loading ? 'spinner' : this.attrs.icon;

		const svg = SvgIconElement.#icons.get(iconName);
		if (!svg) {
			this.shadowRoot.innerHTML = '';
			return;
		}

		const sizePx = `${this.#getSizePx(this.attrs.size)}`;
		const colors = Style.getColorsSet(this.attrs.color);

		let shadowCss = '';
		if (this.attrs.shadow) {
			shadowCss = /* css */`
				svg {
					filter: drop-shadow(-1px 1px 0 var(--shadow-color, ${this.attrs.useBgColor ? colors.bgDark2 : colors.textShadow}));
				}
			`;
		}

		this.shadowRoot.innerHTML = /* css */`
			<style>
				:host {
					display: inline-flex;
					${this.attrs.color !== '' ? `color: ${this.attrs.useBgColor ? colors.bgLight1 : colors.textDark1}` : ''};
				}

				:host([hidden]) {
					display: none;
				}

				${shadowCss}
			</style>
			<svg xmlns="http://www.w3.org/2000/svg" width="${sizePx}" height="${sizePx}" viewBox="${svg.viewBox}">
				${svg.content}
			</svg>
		`;
	}

	renderAttribute(name) {
		if (name === 'size' && this.shadowRoot.firstChild) {
			const sizePx = `${this.#getSizePx(this.attrs.size)}`;

			/** @type {SVGElement} */
			const svg = this.shadowRoot.querySelector('svg');
			svg.setAttribute('width', sizePx);
			svg.setAttribute('height', sizePx);
			return true;
		}

		return false;
	}

	#getSizePx(size) {
		return MIN_SIZE_PX + SIZE_STEP_PX * Math.max(0, (size || 0));
	}

	#buildIconsElemFromString(iconsSource) {
		SvgIconElement.#icons = new Map();
		const list = iconsSource.matchAll(/<symbol [^>]*id=["']([^'"]+)["'][^>]*viewbox=["']([^'"]+)["'][^>]*>(.+?)<\/symbol>/sgi);
		for (let [, id, viewBox, content] of list) {
			SvgIconElement.#icons.set(id, {viewBox, content});
		}

		return true;
	}
}

customElements.define('f-icon', SvgIconElement);
