import PageElement from "ui/PageElement";
import Template from "ui/Template";
import changesBarView from "./html/changes-bar.handlebars";
import {i18n} from "ui/i18n";
import EventDispatcher from "ui/EventDispatcher";
import ChangesLoader from "ui/front/changes/ChangesLoader";
import InteractiveChangesLoader from "ui/front/changes/InteractiveChangesLoader";

const PAGE_LIMIT = 50;

export const EVENT_SET_AREA = 'set-area';
export const EVENT_LOADED = 'loaded';
export const EVENT_SHARE = 'share';
export const EVENT_OPEN_INTERACTIVE_CHANGES = 'interactive-changes';

const LS_KEY = 'last-change';

/**
 * @typedef {object} T_UpdateOnFrontPage
 * @property {number} id id
 * @property {string} d Date
 * @property {string} a Authors
 * @property {string} t Title
 * @property {string} l Link
 * @property {boolean} m Meaningful
 * @property {string} p Points
 * @property {T_UpdateOnFrontPage} [ref]
 * @property {string} [data] Additional information for interactive elements
 * @property {string} [icon] ID of local SVG icon
 */

export default class ChangesBar {
	/**
	 * @param {HTMLElement} cont
	 */
	constructor(cont) {
		this._element = new Template(changesBarView).createElement();
		cont.appendChild(this._element);

		/**
		 * @type {HTMLElement[]}
		 * @private
		 */
		this._records = [];

		/**
		 * @type {T_UpdateOnFrontPage[]|null}
		 * @private
		 */
		this._changes = null;

		/**
		 * @type {T_UpdateOnFrontPage[]|null}
		 * @private
		 */
		this._generalChanges = null;

		/**
		 * @type {T_UpdateOnFrontPage[]|null}
		 * @private
		 */
		this._interactiveChanges = null;

		/**
		 * @type {T_UpdateOnFrontPage[]|null}
		 * @private
		 */
		this._changesFiltered = [];

		/**
		 * @type {number}
		 * @private
		 */
		this._page = 1;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isEOF = false;

		/**
		 * @type {boolean}
		 * @private
		 */
		this._isLoaded = false;

		/**
		 * @type {EventDispatcher}
		 * @private
		 */
		this._events = new EventDispatcher();

		/**
		 * @type {string}
		 * @private
		 */
		this._lastViewedChangeDate = this._getDateOfLastViewedChange();

		/**
		 * @type {Set<T_UpdateOnFrontPage>}
		 * @private
		 */
		this._viewedItems = new Set();
	}

	async init() {
		await globalThis.waitState('changes-bar');

		/**
		 * @type {object}
		 * @private
		 */
		this._myState = globalThis.state('changes-bar');

		await Promise.all([
			this._myState.waitProp('listEl'),
			this._myState.waitProp('searchEl')
		]);


		this._initIntersectionObserver();
		this._initSearchBar();
		this._initNavigator();
	}

	/**
	 * @return {EventDispatcher}
	 */
	get events() {
		return this._events;
	}

	/**
	 * @return {T_UpdateOnFrontPage[]|null}
	 */
	get records() {
		return this._changes;
	}

	/**
	 * @param {boolean|null} flag
	 */
	toggle(flag = null) {
		if (typeof flag === 'boolean') {
			this._element.classList.toggle('changes-bar_show', flag);
		} else {
			this._element.classList.toggle('changes-bar_show');
		}

		if (this._changes === null && !this._isLoaded) {
			this.load().then(() => {
				this._markChangesAsViewed();
			});
		} else {
			this._markChangesAsViewed();
		}

		if (this.isOpen) {
			if (!this._interactiveChanges) {
				this._loadInteractiveChanges().then();
			}
		}
	}

	getLastViewedChangeDate() {
		return this._lastViewedChangeDate;
	}

	/**
	 * @return {boolean}
	 */
	get isOpen() {
		return this._element.classList.contains('changes-bar_show');
	}

	async load() {
		if (this._isLoaded) {
			return;
		}

		this._isLoaded = true;

		const loader = new ChangesLoader();
		if (!(await loader.load())) {
			this._showError(i18n('errors.updates.load'));
			return;
		}

		this._generalChanges = loader.changes;
		this._changes = this._generalChanges;

		this.resetPage();

		this._events.trigger(EVENT_LOADED, this._changes);
	}

	resetPage() {
		this._page = 1;
		this._isEOF = this._changes.length < PAGE_LIMIT;
		this._myState.listEl.shadowRoot.querySelectorAll('f-changes-bar-item').forEach(el => el.remove());
		this._myState.listEl.scrollTop = 0;
		this._applyFilter();
		this.buildPage();
	}

	buildNextPage() {
		if (this._isEOF) {
			return;
		}
		this._page++;
		this.buildPage();
	}

	buildPage() {
		if (!this._changes) {
			return;
		}

		const offset = (this._page - 1) * PAGE_LIMIT;
		if (offset >= this._changesFiltered.length) {
			this._isEOF = true;
			return;
		}

		for (const rec of this._changesFiltered.slice(offset, offset + PAGE_LIMIT)) {
			this.addRecord(rec);
		}
	}

	/**
	 * @param {T_UpdateOnFrontPage} rec
	 */
	addRecord(rec) {
		const el = document.createElement('f-changes-bar-item');
		el.setAttribute('date', rec.d);
		el.setAttribute('author', rec.a || '');
		el.setAttribute('title', rec.t);
		el.setAttribute('icon', rec.icon || '');
		el.setAttribute('link', (rec.l || '').split('||').filter(v => v.startsWith('http')).shift() || '');
		el.setAttribute('interactive', `${/^interactive:\/\//.test(rec.l)}`);
		el.setAttribute('new', `${!this._viewedItems.has(rec) && rec.d > this._lastViewedChangeDate}`);

		this._myState.listEl.shadowRoot.querySelector('[data-role="list"]')
			.insertBefore(el, this._myState.listEl.shadowRoot.querySelector('[data-role="bottom-anchor"]'));
		el.onclick = this._selectRecord.bind(this, rec, el);
	}

	/**
	 * @private
	 */
	_applyFilter() {
		if (!this._changes) {
			this._changesFiltered = [];
			return;
		}

		const list = this._changes.filter(change => !!change.m);

		if (this._changes === this._interactiveChanges) {
			this._changesFiltered = list;
			return;
		}

		let text = this._myState.searchEl.value.replace(/(^\s+)|(\s+$)/, '');
		if (text === '') {
			this._changesFiltered = list;
			return;
		}

		text = text.toLowerCase();

		this._changesFiltered = list.filter((change) => {
			return change.t.toLowerCase().includes(text)
				|| change.a.toLowerCase().includes(text)
				|| change.d.toLowerCase().includes(text);
		});
	}

	/**
	 * @private
	 */
	_initSearchBar() {
		let lastText = '';
		const update = () => {
			if (this._myState.searchEl.value === lastText) {
				return;
			}

			lastText = this._myState.searchEl.value;
			this.resetPage();
		};

		this._myState.searchEl.addEventListener('enter', update);
		this._myState.searchEl.addEventListener('change', update);
		this._myState.searchEl.addEventListener('input', update);
		this._myState.searchEl.addEventListener('keyup', update);
	}

	/**
	 * @private
	 */
	_initNavigator() {
		this._myState.generalBtn.onclick = () => {
			if (this._changes === this._generalChanges) {
				return;
			}
			this._changes = this._generalChanges;
			this._myState.mode = 'general';
			this.resetPage();
		};

		this._myState.interactivesBtn.onclick = async () => {
			if (this._interactiveChanges === null) {
				await this._loadInteractiveChanges();
			}

			if (this._changes === this._interactiveChanges) {
				return;
			}

			this._myState.mode = 'interactives';
			this._myState.unreadInteractives = false;
			this._changes = this._interactiveChanges;

			this.resetPage();

			this._events.trigger(EVENT_OPEN_INTERACTIVE_CHANGES);
		};

		this._myState.mode = 'general';
	}

	async _loadInteractiveChanges() {
		const loader = new InteractiveChangesLoader();
		if (!await loader.load()) {
			this._showError(i18n('errors.updates.load'));
			return;
		}

		this._interactiveChanges = loader.changes;

		const flag = this._interactiveChanges.some(item => item.d > this._lastViewedChangeDate);
		this._myState.unreadInteractives = flag;
	}

	/**
	 * @private
	 */
	_initIntersectionObserver() {
		const observer = new IntersectionObserver((entries) => {
			if (this._isEOF) {
				return;
			}

			for (const entry of entries) {
				if (entry.isIntersecting) {
					this.buildNextPage();
					break;
				}
			}
		});

		observer.observe(this._myState.listEl.shadowRoot.querySelector('[data-role="bottom-anchor"]'));
	}

	/**
	 * @param {string} message
	 * @private
	 */
	_showError(message) {
		this._myState.error = message;
	}

	/**
	 * @param {T_UpdateOnFrontPage} rec
	 * @param {HTMLElement} el
	 * @private
	 */
	_selectRecord(rec, el) {
		const prev = this._myState.listEl.shadowRoot.querySelector('f-changes-bar-item[selected="true"]');
		if (prev) {
			prev.setAttribute('selected', 'false');
		}

		el.setAttribute('selected', 'true');
		el.setAttribute('new', 'false');
		this._viewedItems.add(rec);

		this._events.trigger(EVENT_SET_AREA, rec.ref || rec);
	}

	/**
	 * @return {string}
	 * @private
	 */
	_getDateOfLastViewedChange() {
		let date = '';
		try {
			date = localStorage.getItem(LS_KEY);
		} catch (e) {
		}

		if (date === '' || !/^\d{4}-\d{2}-\d{2}$/.test(date)) {
			this._markChangesAsViewed();
			return new Date().toISOString().split('T')[0];
		}

		return date;
	}

	/**
	 * @private
	 */
	_markChangesAsViewed() {
		localStorage.setItem(LS_KEY, new Date().toISOString().split('T')[0]);
	}
}
