import {Style} from "../../styles/Style.js";
import {TexturesFactory} from "../../textures/TexturesFactory.js";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

class ScrollBarElement extends AbstractCustomElement {
	attrs = {
		selector: ':host',
		xScroll: '',
		yScroll: '',
	};

	/** @type {HTMLStyleElement} */
	#styleEl;

	constructor() {
		super(false);
	}

	async render() {
		if (this.attrs.selector === '') {
			return;
		}

		if (this.attrs.xScroll === '' && this.attrs.yScroll === '') {
			return;
		}

		if (!this.#styleEl) {
			this.#styleEl = document.createElement('style');
		}

		const colors = Style.getColorsSet();

		let bg = '';

		if (this.attrs.yScroll !== '' && this.attrs.xScroll === '') {
			bg = await TexturesFactory.getVerticalLines(4, colors.bgDark2.cssValue);
		} else if (this.attrs.xScroll !== '' && this.attrs.yScroll === '') {
			bg = await TexturesFactory.getHorizontalLines(4, colors.bgDark2.cssValue);
		}

		this.#styleEl.textContent = /* css */`
			${this.attrs.selector} {
				scrollbar-width: 12px;
				scrollbar-color: ${colors.bgDark2};
				overflow: hidden;
				${this.attrs.xScroll !== '' ? `overflow-x: ${this.attrs.xScroll};` : ''}
				${this.attrs.yScroll !== '' ? `overflow-y: ${this.attrs.yScroll};` : ''}

				@supports not selector(::-webkit-scrollbar-thumb) {
					scrollbar-width: thin;
					scrollbar-color: ${colors.bgLight1} ${colors.bgDark2};
				}
			}

			${this.attrs.selector}::-webkit-scrollbar-thumb {
				background-color: ${colors.bg};
				border: 1px solid ${colors.bgDark1};
				border-radius: 3px;
				box-shadow: 0 1px 1px 1px ${colors.bgLight1} inset;
			}

			${this.attrs.selector}::-webkit-scrollbar-track {
				background-color: ${colors.bgDark1};
				${bg !== '' ? `background-image: url("${bg}");` : ''}
				border-radius: 3px;
				box-shadow: 0 0 3px 1px ${colors.bgDark2} inset;
			}

			${this.attrs.selector}::-webkit-scrollbar {
				width: 12px;
				height: 12px;
			}
		`;

		if (!this.#styleEl.parentElement) {
			this.appendChild(this.#styleEl);
		}
	}
}

customElements.define('f-scroll-bar', ScrollBarElement);
