import {detectMobileScreen, detectWebpSupport, detectWorkerAndWasmSupport} from "ui/helpers/detectors";

export const IS_SMALL_VIEW = detectMobileScreen();

export const PROJECT_NAME = 'Floor796';

export const DEPRECATED_LS_KEYS = [
	'f796-render-engine',
	'f796-render-engine-v2',
];

export const F796_RENDER_ENGINE_LS_KEY = 'f796-render-engine-v3';

export const IS_F796_RENDER_ENGINE_SUPPORTED = detectWorkerAndWasmSupport();

export const IS_F796_RENDER_ENGINE = IS_F796_RENDER_ENGINE_SUPPORTED
	&& [null, '1'].includes(window.localStorage.getItem(F796_RENDER_ENGINE_LS_KEY))
	&& !window.location.search.includes('old-render');

export const ZOOM_FACTORS = IS_F796_RENDER_ENGINE
	? (IS_SMALL_VIEW ? [2, 1, 0.7, 0.5] : (/extra-zoom/.test(location.search) ? [4, 3, 2, 1, 0.7] : [2, 1, 0.7]))
	: [1, 0.9, 0.8, 0.7, 0.6, 0.5];

export const ZOOM_FACTORS_EXTENDED = IS_F796_RENDER_ENGINE ? ZOOM_FACTORS : [1.5, 1.2, 1, 0.9, 0.8, 0.7, 0.6, 0.5];
export const ZOOM_STEP = IS_F796_RENDER_ENGINE ? 50 : 20;

export const MIN_ALLOWED_BATTERY_LEVEL = 0.1;

export const LOW_BATTERY_LS_KEY = 'low-battery';
export const LOW_BATTERY_MSG_TIMEOUT = 3600 * 1000;

export const CLIENT_ID_LS_KEY = 'client_id';
export const VISIT_NUMBER_LS_KEY = 'visit';
export const VISIT_TIME_LS_KEY = 'visit_time';
export const SESSION_TTL = 3600_000; // ms
export const UPDATE_SESSION_TIMEOUT = 5000; // ms
export const CLIENT_WS_CONNECTION_DELAY = 15000; // ms
export const CLIENT_MAX_INACTIVE_TIME = 5 * 60 * 1000; // 5 min

export const F796_SLOT_RENDER_WORKER_URL = '/workers/scene-slot-v6.js';

export const LAST_POSITION_LS_KEY = 'last-pos';

export const IS_WEBP_SUPPORTED = detectWebpSupport();
export const IS_CSS_CALC_SIZE_SUPPORTED = CSS.supports('height: calc-size(auto, size)');

/**
 * Total number of frames in scene
 */
export const SCENE_FRAMES = 60;

/**
 * Number of frames that need to show per second
 */
export const SCENE_FPS = 12;

/**
 * Scene source image width
 */
export const SCENE_SOURCE_WIDTH = 1024;

/**
 * Scene source image height
 */
export const SCENE_SOURCE_HEIGHT = 1024;

/**
 * Scene final image width
 */
export const SCENE_FINAL_WIDTH = 1024;

/**
 * Scene final image height
 */
export const SCENE_FINAL_HEIGHT = 1024;

/**
 * Scene grid indent from borders
 */
export const SCENE_GRID_INDENT = 3;

/**
 * Number of columns in sprites map
 */
export const SPRITES_COLUMNS = 7;

export const SCENE_SLOT_WIDTH = 508;
export const SCENE_SLOT_HEIGHT = 406;

/**
 * Walls height part of whole scene height
 */
export const WALL_HEIGHT_COEFFICIENT = 0.2;

/**
 * Lines angle in degrees
 */
export const SCENE_ANGLE = 38.62;

export const IS_HIGH_DPR = window.devicePixelRatio > 1;

export const CACHE_NAME = 'f796';

/**
 * URL to the static content
 */
export const STATIC_URL = (() => {
	if (['floor796.com', 'new.floor796.com', 'old.floor796.com'].includes(window.location.hostname)) {
		return 'https://cdn.floor796.com';
	}
	return '';
})();
