import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "ui/components/elements/AbstractCustomElement.js";
import {TexturesFactory} from "ui/components/textures/TexturesFactory";

class ButtonElement extends AbstractCustomElement {
	attrs = {
		caption: '',
		icon: '',
		iconSize: 0,
		color: 'face',
		hotkey: '',
		small: false,
		toolMode: false,
		groupPosition: '',
		bulbOn: false,
		bulbSize: 12,
		bulbColor: '',
		pressed: false,
	};

	/** @type {?HTMLElement} */
	#buttonEl

	/** @type {?HTMLElement} */
	#captionEl;

	/** @type {?HTMLElement} */
	#iconEl;

	/** @type {?HTMLElement} */
	#bulbEl;

	/** @type {?HTMLElement} */
	#hotkeyEl;

	/** @type {ElementInternals} */
	#internals;

	constructor() {
		super(true, {delegatesFocus: true});
		this.#internals = this.attachInternals();

		this.shadowRoot.addEventListener('keydown', (e) => {
			if (this.#internals.states.has('--pressed')) {
				return;
			}

			if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Space') {
				this.#internals.states.add('--pressed');
			}
		});

		this.shadowRoot.addEventListener('keyup', () => {
			this.#internals.states.delete('--pressed');
		});

		this.shadowRoot.addEventListener('click', () => {
			this.dispatchEvent(new CustomEvent('f-button-click', {detail: this, bubbles: true, composed: true}));
		});
	}

	async render() {
		if (this.shadowRoot.firstChild) {
			this.shadowRoot.innerHTML = '';
		}

		this.#buttonEl = document.createElement('button');

		let promises;

		if (this.attrs.toolMode) {
			promises = this.#appendToolModeStyle();
		} else {
			this.#appendStyle();
		}

		this.#appendBulb();
		this.#appendIcon();
		this.#appendCaption();
		this.#appendHotkey();

		this.shadowRoot.appendChild(this.#buttonEl);

		if (promises && promises.length > 0) {
			const style = document.createElement('style');
			style.textContent = (await Promise.all(promises.map(p => p()))).join('\n');
			this.shadowRoot.appendChild(style);
		}
	}

	renderAttribute(name) {
		if (name === 'bulbOn' && this.#bulbEl) {
			this.#bulbEl.setAttribute('on', this.attrs.bulbOn ? 'true' : 'false');
			return true;
		}

		if (name === 'caption' && this.#captionEl) {
			this.#captionEl.textContent = this.attrs.caption;
			return true;
		}

		if (name === 'icon' && this.#iconEl) {
			this.#iconEl.setAttribute('icon', this.attrs.icon);
			return true;
		}

		if (name === 'disabled' || name === 'pressed') {
			return true;
		}

		return false;
	}

	#appendStyle() {
		const style = document.createElement('style');
		const defaultColorsSet = Style.getColorsSet();
		const dangerColorsSet = Style.getColorsSet('danger');
		const mainColorsSet = Style.getColorsSet(this.attrs.color);

		const isRound = (this.attrs.caption === '' && this.attrs.bulbColor === '' && this.attrs.hotkey === '')
			|| (this.attrs.caption === '' && this.attrs.hotkey === '' && this.attrs.icon === '' && this.attrs.bulbColor !== '');

		style.textContent = /* css */`
			:host {
				display: inline-flex;
				position: relative;
				box-sizing: border-box;
				vertical-align: middle;
				border: none;
				border-radius: ${isRound ? '50%' : Style.getBorderRadiusPx(2, 2)};
				background-image: linear-gradient(${defaultColorsSet.bgDark1}, ${defaultColorsSet.bgLight1});
				padding: 3px;
			}

			:host::before {
				content: '';
				position: absolute;
				inset: 1px 1px 1px 1px;
				border-radius: ${isRound ? '50%' : Style.getBorderRadiusPx(2, 1)};
				background-color: ${defaultColorsSet.bgDark2};
			}

			button {
				position: relative;
				appearance: none;
				-webkit-appearance: none;
				display: inline-flex;
				box-sizing: border-box;
				cursor: pointer;
				align-items: center;
				user-select: none;
				white-space: nowrap;
				justify-content: center;
				${isRound ? `width: ${Style.getBaseHeightPx(this.attrs.small ? 2 : 3)}; aspect-ratio: 1;` : ''}
				font-size: ${Style.getFontSizePx(this.attrs.small ? `small` : `default`)};
				border-radius: ${isRound ? '50%' : Style.getBorderRadiusPx(2)};
				padding: 0 ${isRound ? 0 : Style.getIndentPx(this.attrs.small ? 2 : 3)};
				${this.attrs.hotkey !== '' && !isRound ? `padding-right: ${Style.getIndentPx(1)};` : ''}
				height: ${Style.getBaseHeightPx(this.attrs.small ? 2 : 3)};
				background-color: ${mainColorsSet.bg};
				border: none;
				color: ${mainColorsSet.textDark1};
				text-shadow: -1px 1px 0 ${mainColorsSet.textShadow};
				outline: none;
				box-shadow:
					0 -1px 1px ${mainColorsSet.bgDark2} inset,
					0 1px 1px 1px ${mainColorsSet.bgLight2} inset;

				f-bulb {
					margin-right: ${isRound ? 0 : 4}px;
				}

				f-icon {
					--shadow-color: ${mainColorsSet.textShadow};
				}
			}

			kbd {
				position: relative;
				margin-top: ${Style.getIndentPx(this.attrs.small ? 2 : 4)};
				margin-left: ${Style.getIndentPx(1)};
				font-size: ${Style.getFontSizePx('kbd')};
				color: ${mainColorsSet.textDark2};
			}

			:host(:where(:hover, :active, :focus):not([disabled]:not([disabled="false"])))::before {
				box-shadow: 0 0 2px 1px var(--glow-color) inset;
			}

			:host(:where(:hover, :active, :focus):not([disabled]:not([disabled="false"]))) button::before {
				content: '';
				position: absolute;
				inset: 0 0 0 0;
				border-radius: ${isRound ? '50%' : Style.getBorderRadiusPx(2)};
				mix-blend-mode: hard-light;
				box-shadow:
					0 0 6px 1px var(--glow-color),
					0 0 3px var(--glow-color) inset;
			}

			:host(:focus:not([disabled]:not([disabled="false"]))) {
				--glow-color: color-mix(in srgb, ${mainColorsSet.glow}, transparent 50%);
			}

			:host(:is(:hover, :active):not([disabled]:not([disabled="false"]))) {
				--glow-color: ${mainColorsSet.glow};
			}

			:host(:is(:active):not([disabled]:not([disabled="false"]))) button {
				transform: translateY(1px);
			}

			:host(:is(:active):not([disabled]:not([disabled="false"])))::before {
				box-shadow: 0 0 4px 3px ${mainColorsSet.glow} inset;
			}

			:host(:is(:active):not([disabled]:not([disabled="false"]))) button {
				background-color: color-mix(in srgb, ${mainColorsSet.bg}, ${mainColorsSet.glow} 7%);
			}

			:host([disabled]:not([disabled="false"])) {
				pointer-events: none;
                padding: 3px;
            }

			:host([disabled]:not([disabled="false"]))::before {
				box-shadow: 0 0 5px 2px color-mix(in srgb, ${mainColorsSet.bg}, ${dangerColorsSet.bgDark1} 60%) inset;
			}

			:host([disabled]:not([disabled="false"])) button {
				cursor: not-allowed;
				background-color: ${mainColorsSet.bgDark1};
				box-shadow:
					0 -1px 1px color-mix(in srgb, ${mainColorsSet.bgDark2}, ${dangerColorsSet.bgDark2} 70%) inset,
					0 1px 1px 1px color-mix(in srgb, ${mainColorsSet.bgLight1}, ${dangerColorsSet.bgDark2} 40%) inset;

				color: ${mainColorsSet.textDark2};
				opacity: 0.8;
			}

			:host([hidden]) {
				display: none;
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#appendToolModeStyle() {
		const promises = [];
		const style = document.createElement('style');
		const mainColorsSet = Style.getColorsSet(this.attrs.color);
		const defaultColorsSet = this.attrs.color === 'light' ? mainColorsSet : Style.getColorsSet();
		const successColorsSet = Style.getColorsSet('success');
		const warningColorsSet = Style.getColorsSet('warning');
		const gp = this.attrs.groupPosition;
		const radius = '3px';

		promises.push(async () => {
			return /* css */`
				button {
					background-image: url("${await TexturesFactory.getScratches(true)}");
				}
			`;
		});

		style.textContent = /* css */`
			:host {
				display: inline-flex;
				position: relative;
				box-sizing: border-box;
				vertical-align: middle;
				border: none;
				border-radius:
					${gp === 'right' || gp === 'center' ? '0' : radius}
					${gp === 'left' || gp === 'center' ? '0' : radius}
					${gp === 'left' || gp === 'center' ? '0' : radius}
					${gp === 'right' || gp === 'center' ? '0' : radius};
				margin-left: ${gp === 'right' || gp === 'center' ? '-2px' : '0'};
				padding-top: 1px;
				padding-bottom: 4px;
				padding-left: ${gp === 'right' || gp === 'center' ? '2px' : '3px'};
				padding-right: ${gp === 'left' || gp === 'center' ? '2px' : '3px'};
				background-image: linear-gradient(${defaultColorsSet.bgDark1}, ${defaultColorsSet.bgLight1});
				z-index: 1;
			}

			:host::before {
				content: '';
				position: absolute;
				border-radius:
					${gp === 'right' || gp === 'center' ? '0' : radius}
					${gp === 'left' || gp === 'center' ? '0' : radius}
					${gp === 'left' || gp === 'center' ? '0' : radius}
					${gp === 'right' || gp === 'center' ? '0' : radius};
				inset:
					1px
					${gp === 'left' || gp === 'center' ? '0' : '1px'}
					0
					${gp === 'right' || gp === 'center' ? '0' : '1px'};
				background-color: ${defaultColorsSet.bgDark2};
			}

			:host::after {
				content: '';
				position: absolute;
				border-radius:
					0 0
					${gp === 'left' || gp === 'center' ? '0' : radius}
					${gp === 'right' || gp === 'center' ? '0' : radius};
				right: ${gp === 'left' || gp === 'center' ? '2px' : '3px'};
				left: ${gp === 'right' || gp === 'center' ? '2px' : '3px'};
				bottom: 1px;
				height: 5px;
				background-color: color-mix(in srgb, ${defaultColorsSet.bg}, ${defaultColorsSet.bgDark1} 50%);
				box-shadow: 0 1px 1px ${defaultColorsSet.bgDark1} inset,
					0 -1px 1px  color-mix(in srgb, ${defaultColorsSet.bg}, ${defaultColorsSet.bgDark1} 80%) inset;
			}

			button {
				position: relative;
				appearance: none;
				-webkit-appearance: none;
				display: inline-flex;
				box-sizing: border-box;
				cursor: pointer;
				align-items: center;
				user-select: none;
				white-space: nowrap;
				font-size: ${Style.getFontSizePx(this.attrs.small ? `small` : `default`)};
				border-radius:
					${gp === 'right' || gp === 'center' ? '0' : radius}
					${gp === 'left' || gp === 'center' ? '0' : radius}
					0 0;
				padding: 0 ${Style.getIndentPx(this.attrs.small ? 2 : 3)};
				${this.attrs.hotkey !== '' ? `padding-right: ${Style.getIndentPx(1)};` : ''}
				height: calc(${Style.getBaseHeightPx(this.attrs.small ? 2 : 3)} - 3px);
				background-color: ${mainColorsSet.bg};
				background-position:
					calc(${Math.ceil(Math.random() * 200)}px)
					calc(${Math.ceil(Math.random() * 200)}px);
				border: none;
				box-shadow: 0 0 1px 1px ${mainColorsSet.bgLight1} inset;
				color: ${mainColorsSet.textDark1};
				text-shadow: -1px 1px 0 ${mainColorsSet.textShadow};
				outline: none;

				f-bulb {
					margin-right: 4px;
				}

				f-icon {
					--shadow-color: ${mainColorsSet.textShadow};
				}
			}

			kbd {
				position: relative;
				margin-top: ${Style.getIndentPx(this.attrs.small ? 2 : 4)};
				margin-left: ${Style.getIndentPx(1)};
				font-size: ${Style.getFontSizePx('kbd')};
				color: ${mainColorsSet.textDark2};
			}

			:host(:hover:not([disabled]):not(:where([pressed=""],[pressed="true"],[pressed="pressed"]))) {
				z-index: 3;
			}

			:host(:hover:not([disabled]):not(:where([pressed=""],[pressed="true"],[pressed="pressed"])))::before {
				box-shadow: 0 0 5px 1px ${mainColorsSet.glow} inset;
			}

			:host(:hover:not([disabled]):not(:where([pressed=""],[pressed="true"],[pressed="pressed"])))::after {
				background-color: color-mix(in srgb, ${defaultColorsSet.bg}, ${mainColorsSet.glow} 60%);
				box-shadow: 0 -1px 2px color-mix(in srgb, ${mainColorsSet.glow}, transparent 50%) inset;
			}

			:host(:hover:not([disabled]):not(:where([pressed=""],[pressed="true"],[pressed="pressed"]))) button {
				background-color: color-mix(in srgb, ${defaultColorsSet.bg}, ${mainColorsSet.glow} 5%);
				box-shadow: 0 3px 6px 0 ${mainColorsSet.glow},
					0 0 1px 1px ${mainColorsSet.bgLight1} inset,
					0 0 3px ${mainColorsSet.glow} inset;
			}

			:host(:where([pressed=""],[pressed="true"],[pressed="pressed"]):not([disabled])) {
				z-index: 2;
			}

			:host(:where([pressed=""],[pressed="true"],[pressed="pressed"]):not([disabled]))::before {
				box-shadow: 0 0 5px 1px color-mix(in srgb, ${successColorsSet.glow}, transparent 50%) inset;
			}

			:host(:where([pressed=""],[pressed="true"],[pressed="pressed"]):not([disabled]))::after {
				background-color: ${successColorsSet.glow};
				box-shadow: 0 0 4px 1px ${successColorsSet.glow};
			}

			:host(:where([pressed=""],[pressed="true"],[pressed="pressed"]):not([disabled])) button {
				background-color: color-mix(in srgb, ${mainColorsSet.bg}, ${successColorsSet.glow} 8%);
				box-shadow:
					0 0 1px 1px color-mix(in srgb, ${mainColorsSet.bgLight1},
					${successColorsSet.glow} 30%) inset,
					0 3px 6px 1px color-mix(in srgb, ${successColorsSet.glow}, transparent 50%);
				color: color-mix(in srgb, ${mainColorsSet.textDark1}, ${successColorsSet.glow} 10%);
				text-shadow: -1px 1px 0 color-mix(in srgb, ${mainColorsSet.textShadow}, ${successColorsSet.glow} 10%);
			}

			:host(:active:not([disabled])) {
				z-index: 4;
			}

			:host(:active:not([disabled]))::before {
				box-shadow: 0 0 5px 1px color-mix(in srgb, ${warningColorsSet.glow}, transparent 50%) inset;
			}

			:host(:active:not([disabled]))::after {
				transform: translateY(1px);
				background-color: ${warningColorsSet.glow};
				box-shadow: 0 0 4px 1px ${warningColorsSet.glow};
			}

			:host(:active:not([disabled])) button {
				transform: translateY(1px);
				background-color: color-mix(in srgb, ${mainColorsSet.bg}, ${warningColorsSet.glow} 8%);
				box-shadow:
					0 0 1px 1px color-mix(in srgb, ${mainColorsSet.bgLight1},
					${warningColorsSet.glow} 30%) inset,
					0 3px 6px 1px color-mix(in srgb, ${warningColorsSet.glow}, transparent 50%);
			}

			:host([hidden]) {
				display: none;
			}
		`;
		this.shadowRoot.appendChild(style);

		return promises;
	}

	#appendIcon() {
		if (!this.attrs.icon) {
			this.#iconEl = undefined;
			return;
		}

		this.#iconEl = document.createElement('f-icon');
		this.#iconEl.setAttribute('icon', this.attrs.icon);
		this.#iconEl.setAttribute(
			'size',
			String(this.attrs.iconSize > 0 ? this.attrs.iconSize : (this.attrs.small ? 1 : 2))
		);

		this.#buttonEl.appendChild(this.#iconEl);
	}

	#appendBulb() {
		if (this.attrs.bulbColor === '') {
			this.#bulbEl = undefined;
			return;
		}

		this.#bulbEl = document.createElement('f-bulb');
		this.#bulbEl.setAttribute('color', this.attrs.bulbColor);
		this.#bulbEl.setAttribute('size', `${this.attrs.bulbSize}`);

		if (this.attrs.bulbOn) {
			this.#bulbEl.setAttribute('on', '');
		}

		this.#buttonEl.appendChild(this.#bulbEl);
	}

	#appendCaption() {
		let caption = '';

		if (this.attrs.caption !== '') {
			caption = this.attrs.caption;
		} else if (this.firstChild) {
			caption = this.firstChild.nodeValue || '';
		}

		if (caption === '') {
			this.#captionEl = undefined;
			return;
		}

		this.#captionEl = document.createElement('span');
		this.#captionEl.textContent = caption;

		if (this.attrs.icon) {
			this.#captionEl.style.marginLeft = Style.getIndentPx(1);
		}

		this.#buttonEl.appendChild(this.#captionEl);
	}

	#appendHotkey() {
		if (!this.attrs.hotkey) {
			return;
		}

		this.#hotkeyEl = document.createElement('kbd');
		this.#hotkeyEl.textContent = this.attrs.hotkey;
		this.#buttonEl.appendChild(this.#hotkeyEl);
	}
}

customElements.define('f-button', ButtonElement);
