import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

export class BulbElement extends AbstractCustomElement {
	attrs = {
		color: 'danger',
		blink: false,
		size: 12,
		on: false,
	};

	#blinkTimerId = 0;
	#blinkFlag = false;

	renderAttribute(name) {
		if (name === 'blink') {
			this.#blink();
			return true;
		}

		return name === 'on';
	}

	async render() {
		this.shadowRoot.innerHTML = '<div></div>';
		this.#appendStyle();

		if (this.attrs.blink) {
			this.#blink();
		}
	}

	#blink() {
		clearInterval(this.#blinkTimerId);
		if (this.attrs.blink) {
			this.#blinkTimerId = setInterval(() => {
				this.#blinkFlag = !this.#blinkFlag;
				this.setAttribute('on', `${this.#blinkFlag}`);
			}, 50);
		} else {
			this.#blinkFlag = false;
			this.removeAttribute('on');
		}
	}

	#appendStyle() {
		const style = document.createElement('style');
		const colors = Style.getColorsSet();
		const mainColors = Style.getColorsSet(this.attrs.color);

		let lightStyle;
		if (this.attrs.color === 'success') {
			lightStyle = /* css */`
			:host(:is([on=""],[on="true"])) {
				background-image: linear-gradient(${colors.bgDark1}, ${mainColors.bg});
			}

			:host(:is([on=""],[on="true"]))::before {
				background-color: color-mix(in srgb, ${colors.bgDark2}, ${mainColors.bg} 30%);
				box-shadow: 0 0 6px ${mainColors.glow};
			}

			:host(:is([on=""],[on="true"])) div {
				box-shadow: 0 0 3px ${mainColors.glow};
				background-image: radial-gradient(${mainColors.glow}, ${mainColors.bg});

				&::before {
					background-image: linear-gradient(#fff, transparent 40%);
				}
			}
			`;
		} else {
			lightStyle = /* css */`
				:host(:is([on=""],[on="true"])) {
					background-image: linear-gradient(${colors.bgDark1}, ${mainColors.bg});
				}

				:host(:is([on=""],[on="true"]))::before {
					background-color: color-mix(in srgb, ${colors.bgDark2}, ${mainColors.bg} 30%);
					box-shadow: 0 0 6px ${mainColors.bgLight2};
				}

				:host(:is([on=""],[on="true"])) div {
					box-shadow: 0 0 3px ${mainColors.bg};
					background-image: radial-gradient(${mainColors.bgLight2}, ${mainColors.bgDark1});

					&::before {
						background-image: linear-gradient(#fff, transparent 40%);
					}
				}
			`;
		}

		style.textContent = /* css */`
			:host {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				min-width: ${this.attrs.size}px;
				width: ${this.attrs.size}px;
				height: ${this.attrs.size}px;
				border-radius: 50%;
				box-sizing: border-box;
				position: relative;
				outline: none;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bgLight1});

			}

			:host::before {
				content: '';
				position: absolute;
				inset: 1px 1px 1px 1px;
				border-radius: 50%;
				background-color: ${colors.bgDark2};
				z-index: 1;
			}

			:host div {
				position: absolute;
				top: 2px;
				left: 2px;
				width: ${this.attrs.size - 4}px;
				height: ${this.attrs.size - 4}px;
				border-radius: 50%;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bg});
				z-index: 2;

				&::before {
					content: '';
					position: absolute;
					inset: 1px;
					border-radius: 50%;
					background-image: linear-gradient(${colors.bgLight2}, transparent 40%);
				}
			}

			${lightStyle}
		`;
		this.shadowRoot.appendChild(style);
	}
}

customElements.define('f-bulb', BulbElement);
