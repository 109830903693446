import {AbstractCustomElement} from "ui/components/elements/AbstractCustomElement";
import {Style} from "ui/components/styles/Style";
import {TexturesFactory} from "ui/components/textures/TexturesFactory";
import {escapeHtml} from "ui/helpers/formatters";

let openedModalDialogs = new Set();

class DialogElement extends AbstractCustomElement {
	attrs = {
		caption: '',
		modal: false,
		width: 900,
		position: '',
		padding: '',
		unclosable: false,
		indestructible: false,
		color: 'default',
	}

	/** @type {?Node[]} */
	#initialNodes;

	#keydownHandler;

	connectedCallback() {
		super.connectedCallback();

		if (this.hasAttribute('modal')) {
			openedModalDialogs.add(this);
		}

		if (!this.attrs.unclosable) {
			if (!this.#keydownHandler) {
				this.#keydownHandler = this.#handleKeyDown.bind(this);
			}
			document.addEventListener('keydown', this.#keydownHandler);
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();

		openedModalDialogs.delete(this);

		if (this.#keydownHandler) {
			document.removeEventListener('keydown', this.#keydownHandler);
		}

		this.dispatchEvent(new CustomEvent('f-dialog-close'));
	}

	renderAttribute(name) {
		if (name === 'width') {
			this.style.setProperty('--width', this.attrs.width);
			return true;
		}

		return false;
	}

	async render() {
		if (this.attrs.modal) {
			openedModalDialogs.add(this);
		}

		this.shadowRoot.innerHTML = '';
		const mainStylePromises = this.#appendStyle();

		const dialog = document.createElement('div');
		dialog.className = 'dialog';

		const nestedStylePromises = this.#appendCaptionBar(dialog);

		this.#appendBody(dialog);

		this.shadowRoot.appendChild(dialog);

		if (mainStylePromises.length > 0) {
			const style = document.createElement('style');
			style.textContent = (await Promise.all(mainStylePromises.map(p => p()))).join('\n');
			this.shadowRoot.appendChild(style);
		}

		if (nestedStylePromises.length > 0) {
			await Promise.all(nestedStylePromises.map(p => p()));
		}
	}

	#appendStyle() {
		const promises = [];
		const style = document.createElement('style');
		const lightColors = Style.getColorsSet('face');

		let modalStyle = '';

		if (this.attrs.modal) {
			modalStyle = /* css */`
				:host {
					position: fixed;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: ${1000 + openedModalDialogs.size};
					display: flex;
					align-items: center;
					justify-content: center;
					animation-name: dialog-bg-show;
					animation-fill-mode: forwards;
					animation-timing-function: linear;
					animation-duration: 0.1s;
				}
			`;
		}

		promises.push(async () => {
			return /* css */`
				.dialog {
					background-image: url("${await TexturesFactory.getScratches(true)}");
				}
			`;
		});

		style.textContent = /* css */`
			:host {
				--width: ${this.attrs.width}px;
				${this.#getPositionStyle()}
			}

			${modalStyle}

			.dialog {
				width: var(--width, 800px);
				max-width: 95vw;
				height: auto;
				min-height: 0;
				max-height: 95vh;
				padding: ${Style.getIndentPx(2)};
				padding-top: 0;
				border-radius: ${Style.getBorderRadiusPx(2)};
				background-color: ${lightColors.bg};
				background-position:
					calc(${Math.ceil(Math.random() * 200)}px)
					calc(${Math.ceil(Math.random() * 200)}px);
				backdrop-filter: blur(5px);
				box-sizing: border-box;
				box-shadow:
					0 1px 2px 1px ${lightColors.bgLight2} inset,
					0 -1px 2px 1px ${lightColors.bgDark2} inset;

				animation-name: dialog-show;
				animation-fill-mode: forwards;
				animation-timing-function: ease-out;
				animation-duration: 0.1s;
			}

			.caption {
				color: #111;
				padding: ${Style.getIndentPx(1)} 0;
			}

			.body {
				border-radius: 4px !important;
				height: auto;
				min-height: 100px;
			}

			@keyframes dialog-bg-show {
				from {
					background-color: rgba(0, 0, 0, 0);
				}

				to {
					background-color: rgba(0, 0, 0, 0.4);
				}
			}


			@keyframes dialog-show {
				from {
					transform: scale(0.8) translate(0, -40px);
				}

				to {
					transform: scale(1) translate(0, 0);
				}
			}

			:host([hidden]) {
				display: none;
			}
		`;
		this.shadowRoot.appendChild(style);

		return promises;
	}

	/**
	 * @param {HTMLElement} cont
	 */
	#appendCaptionBar(cont) {
		const promises = [];
		const baseColors = Style.getColorsSet('danger');
		const faceColors = Style.getColorsSet('face');
		const defaultColors = Style.getColorsSet();
		const captionEl = document.createElement('f-box');
		captionEl.className = 'caption';
		captionEl.setAttribute('flex', '');
		captionEl.setAttribute('gap', '1');

		promises.push(async () => {
			const bg = await Promise.all([
				TexturesFactory.getHorizontalLines(4, faceColors.bgDark2.cssValue),
				TexturesFactory.getHorizontalLines(4, faceColors.bg.cssValue),
			]);

			const style = document.createElement('style');
			style.textContent = /* css */`
				.line-decor {
					background-image: url("${bg[0]}"), url("${bg[1]}");
				}
			`;
			captionEl.shadowRoot.appendChild(style);
		});

		const style = /* css */`
			.bulb-decor {
				display: flex;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				align-items: center;
				justify-content: center;
				box-shadow:
					-1px 1px 1px color-mix(in srgb, ${faceColors.bg}, ${faceColors.bgLight1} 70%),
					1px -1px 1px ${faceColors.bgDark1},
					-1px 1px 1px color-mix(in srgb, ${faceColors.bg}, ${faceColors.bgLight1} 70%) inset,
					1px -1px 1px ${faceColors.bgDark1} inset;
			}

			.line-decor {
				opacity: 0.7;
				flex: 1;
				width: 100%;
				height: 10px;
				margin-top: 1px;
				border-radius: 5px;
				border: 1px solid ${faceColors.bgDark2};
				border-bottom-color: ${faceColors.bgLight1};
				background-color: ${faceColors.bgDark1};
				background-position: 0 0, 1px 0;
			}

			.caption {
				margin-right: 6px;
				color: ${faceColors.text};
				text-shadow: -1px 1px 0 ${faceColors.textShadow};
			}

			.button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 36px;
				height: 18px;
				margin-left: 6px;
				border-radius: 3px;
				color: ${baseColors.text};
				border: 1px solid ${baseColors.bgDark1};
				box-shadow:
					0 1px 1px ${baseColors.bgLight2} inset,
					0 -1px 1px ${baseColors.bgDark1} inset,
					0 -1px 0 1px ${defaultColors.bgDark2},
					0 1px 0 1px ${defaultColors.bgLight2};
				background-color: ${baseColors.bg};
				--shadow-color: ${baseColors.textShadow};
				cursor: pointer;

				&:hover {
					background-color: color-mix(in srgb, ${baseColors.bg}, ${baseColors.bgLight1} 40%);
				}

				&:active {
					transform: translateY(1px);
				}
			}
		`;

		let closeButton = '';
		if (!this.attrs.unclosable) {
			closeButton = /* html */`
				<div class="button">
					<f-icon icon="close" size="1"></f-icon>
				</div>
			`;
		}

		let captionHtml = '';
		if (this.attrs.caption !== '') {
			captionHtml = /* html */`<span class="caption">${escapeHtml(this.attrs.caption)}</span>`;
		}

		captionEl.innerHTML = /* html */`
			<style>${style}</style>
			<div class="bulb-decor">
				<f-bulb color="${this.attrs.color !== 'default' ? this.attrs.color : 'success'}" on></f-bulb>
			</div>
			${captionHtml}
			<div class="line-decor"></div>
			${closeButton}
		`;

		if (!this.attrs.unclosable) {
			captionEl.querySelector('.button').onclick = () => {
				this.#destroy();
			}
		}

		cont.appendChild(captionEl);

		return promises;
	}

	/**
	 * @param {HTMLElement} cont
	 */
	#appendBody(cont) {
		if (!this.#initialNodes) {
			this.#initialNodes = [...this.childNodes];
		}
		const bodyEl = document.createElement('f-box');
		bodyEl.style.position = 'relative';
		bodyEl.setAttribute('color', this.attrs.color);

		if (this.attrs.padding !== '') {
			bodyEl.setAttribute('padding', this.attrs.padding);
		}

		if (this.attrs.color === 'default') {
			bodyEl.setAttribute('y-scroll', 'auto');
		}

		bodyEl.className = 'body';

		bodyEl.addEventListener('click', (e) => {
			e.stopPropagation();
		});

		for (const el of this.#initialNodes) {
			bodyEl.appendChild(el);
		}
		cont.appendChild(bodyEl);
	}

	#getPositionStyle() {
		if (this.attrs.position === '' && this.attrs.modal) {
			return '';
		}

		let positionStyle = 'position: absolute;';
		for (const p of this.attrs.position.split(/\W+/)) {
			positionStyle += `${p}: 0;`
		}

		return positionStyle;
	}

	#handleKeyDown(e) {
		if (this.hidden || [...openedModalDialogs.values()][openedModalDialogs.size - 1] !== this) {
			return;
		}

		if (e.code !== 'Escape') {
			return;
		}

		if (e.target && 'tagName' in e.target && ['TEXTAREA', 'INPUT', 'SELECT'].includes(e.target.tagName)) {
			return;
		}

		this.#destroy();
	}

	#destroy() {
		openedModalDialogs.delete(this);
		if (this.attrs.indestructible) {
			this.hidden = true;
			return;
		}

		this.remove();
	}
}

customElements.define('f-dialog', DialogElement);
