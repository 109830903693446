import Utils from "../../../helpers/Utils.js";

/**
 * @param {string} title
 * @param {string} url
 */
export async function createInteractiveDialog(title, url) {
	const initWidth = 500;

	const iframeEl = document.createElement('iframe');
	iframeEl.setAttribute('frameborder', '0');
	iframeEl.setAttribute('width', '100%');
	iframeEl.setAttribute('height', '200');
	iframeEl.style.visibility = 'hidden';

	const screenEl = document.createElement('f-screen');
	screenEl.setAttribute('loading', 'true');
	screenEl.setAttribute('width', `98%`);
	screenEl.setAttribute('height', '96%');
	screenEl.style.position = 'absolute';
	screenEl.style.top = '2%';
	screenEl.style.left = '1%';

	const dialog = document.createElement('f-dialog');
	dialog.setAttribute('caption', title);
	dialog.setAttribute('width', `${initWidth}`);
	dialog.setAttribute('modal', '');
	dialog.setAttribute('padding', '0');
	dialog.appendChild(iframeEl);
	dialog.appendChild(screenEl);

	document.body.appendChild(dialog);

	iframeEl.addEventListener('custom-load', async (e) => {
		let [width, height, hideScrollBar] = e.detail;

		width = Math.min(width, Math.ceil(window.innerWidth * 0.9));
		height = Math.min(height, Math.ceil(window.innerHeight * 0.9));

		if (dialog.offsetWidth < initWidth || width >= dialog.offsetWidth) {
			width = dialog.offsetWidth - 40;
		}

		iframeEl.width = `${width}px`;
		iframeEl.height = `${height}px`;

		if (hideScrollBar) {
			iframeEl.setAttribute('scrolling', 'no');
		}

		dialog.setAttribute('width', `${width + 40}`);

		// wait until all custom elements will be appeared and for proper UI effect
		await Utils.wait(200);

		screenEl.remove();

		iframeEl.style.visibility = '';
		iframeEl.contentWindow.focus();
	});
	iframeEl.src = url + (url.indexOf('?') > -1 ? '&' : '?') + 'v=4';

	return new Promise(r => {
		dialog.addEventListener('f-dialog-close', () => r());
	});
}
