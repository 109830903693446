import {CACHE_NAME} from "ui/config";

export default class CacheCleaner {
	/**
	 * @param {T_MatrixData} matrix
	 */
	constructor(matrix) {
		/**
		 * @type {T_MatrixData}
		 * @private
		 */
		this._matrix = matrix;

		/**
		 * @type {Cache|null}
		 * @private
		 */
		this._cache = null;
	}

	/**
	 * @return {Promise<void>}
	 */
	async clear() {
		const cache = await this._getCacheObject();
		if (!cache) {
			return;
		}

		const convertUrl = url => url.split(/\//).slice(-3).join('/');

		const existingUrls = [];
		for (const row of this._matrix.mat) {
			for (const cell of row) {
				if (!Array.isArray(cell.video.f796)) {
					continue;
				}

				for (const url of cell.video.f796) {
					existingUrls.push(convertUrl(url));
				}
			}
		}

		try {
			(await cache.keys()).forEach(/** @param {Request} request */ request => {
				let url = request.url;
				if (url.indexOf('/interactive') > -1) {
					// skip files from the interactive, because they never change
					return;
				}

				url = convertUrl(url);

				if (!existingUrls.includes(url)) {
					console.log('Remove from the cache: ' + url);
					cache.delete(request.url).then();
				}
			});
		} catch (e) {
			console.log(e);
		}
	}

	/**
	 * @private
	 */
	async _getCacheObject() {
		if (this._cache) {
			return this._cache;
		}

		if ('caches' in globalThis) {
			try {
				this._cache = await globalThis.caches.open(CACHE_NAME);
			} catch (e) {
				console.log(e);
				return null;
			}

			return this._cache;
		}

		return null;
	}
}
